import { React, useContext, useCallback, useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";

import { FaExclamationCircle } from "react-icons/fa";

import { AuthContext } from "../../shared/context/AuthContext";
import { useHttpClient } from "../../shared/hooks/http-hook";

import "./CheckoutPage.css";

const CheckoutPage = () => {
    const external_reference = useParams().orderId;
    console.log("entro aca "+external_reference)
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let status = params.get("status");
    /*let search = window.location.search;
    let params = new URLSearchParams(search);
    let status = params.get("status");
    let payment_id = params.get("payment_id");
    let external_reference = params.get("external_reference");
    let payment_type = params.get("payment_type");*/
   

    const paymentMethods = {
        credit_card: "tarjeta de crédito",
        debit_card: "tarjeta de débito",
        ticket: "Rapipago/Pago Fácil",
    };

    const [order, setOrder] = useState(null);

    const { sendRequest } = useHttpClient();

    const auth = useContext(AuthContext);

    const orderFetch = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/orders/${external_reference}`,
                "GET",
                null,
                { Authorization: "Bearer " + auth.token }
            );
            setOrder(responseData.order);
        } catch (err) {}
    }, [sendRequest, auth.userId, auth.token, external_reference]);

    useEffect(() => {
        orderFetch();
    }, [orderFetch]);

    return (
        <div className="checkout-page">
            {status !== "null" && (
                <>
                    <h2 className="checkout-page__title">¡Felicitaciones!</h2>
                    <div className="checkout-page__payment-confirmation">
                        Tu compra fue realizada de manera exitosa <br /> Usaste{" "}
                        <div className="checkout-page__payment-amount">
                            $ {order && order.total}
                        </div>{" "}
                       
                    </div>
                    <div className="checkout-page_payment-code-header">
                        El código de tu compra es: 
                    </div>
                    <div className="checkout-page__payment-code">
                    {external_reference}
                    </div>
                    <Link
                        to="/"
                        className="checkout-page__purchase-details-link"
                    >
                        Descargar detalle de mi compra
                    </Link>
                    <div className="checkout-page__information">
                        <div className="checkout-page__information-icon">
                            <FaExclamationCircle />
                        </div>
                        <div className="checkout-page__information-text">
                            Te enviaremos el voucher al mail registrado.
                        </div>
                    </div>
                    <div className="checkout-page__footer">
                        ¡Te deseamos mucha suerte!
                    </div>
                    <div className="checkout-page__reminder">
                        (Psss recuerda que puedes canjear tantos Gift Card
                        como quieras...)
                    </div>
                </>
            )}
            {status === "null" && order && (
                <>
                    <h2 className="checkout-page__title">
                        Tu canje aún no fue completado
                    </h2>
                    <div className="checkout-page__payment-confirmation">
                        Tu compra por{" "}
                        <div className="checkout-page__payment-amount">
                            $ {order && order.total}
                        </div>{" "}
                        no ha podido completarse.
                    </div>
                    <div className="checkout-page_payment-code-header">
                        El código de tu orden en nuestro sitio es:
                    </div>
                    <div className="checkout-page__payment-code">
                        {external_reference}
                    </div>
                    <a
                        href={`${order.preferenceLink}`}
                        className="checkout-page__purchase-details-link"
                    >
                        Click aquí para volver a MercadoPago
                    </a>
                    <div className="checkout-page__fail-reminder">
                        Tenes hasta el **placeholder** para completar la canje
                        en caso de que no puedas hacerlo en este momento. Podes
                        encontrar el link para reanudarla en "Mis Canjes"
                        dentro de tu perfil.
                    </div>
                </>
            )}
        </div>
    );
};

export default CheckoutPage;
