import React from "react";

import './AboutUsText.css'

const AboutUsText = () =>{

    return(
    <div className="aboutus__content">
        <h3 className="aboutus__title">On<span className="aboutus__title-color">Lab</span></h3>
        <p className="aboutus__subcontent" >Con Fidelogist ganas Gift Card en tu día a día para que los uses al instante donde quieras.Puedes ganar Gift Card en tu lugar de trabajo como incentivo por cumplir metas, entrenamientos, encuestas, aguinaldos y más.</p>
        <h3 className="aboutus__title--big"><span className="aboutus__title--bigcolor">Con </span>Fidelogist<span className="aboutus__title--bigcolor"> podes canjear todo.</span></h3>
        <p className="aboutus__subcontent" >También ganas Gift Card Fidelogist en tus compras de supermercados y tiendas favoritas, aumentando de manera inmediata entre 3 y 8 veces los beneficios que te entregan sus actuales programas de Gift Card.</p>
        <h3 className="aboutus__title">Nuestra filosofía</h3>
        <p className="aboutus__subcontent" >Con Fidelogist es mucho más fácil y rápido canjear tus Gift Card, ya que los puedes utilizar desde tu teléfono en más de 3.500 tiendas a lo largo de todo el país, de manera inmediata y sin restricciones.</p>
        
        <p className="aboutus__subcontent--bullet" >Los Gift Card no tienen ningún valor comercial, no siendo negociables ni canjeables por dinero.</p>
    
        <p className="aboutus__subcontent--bullet" >Los Gift Card no son embargables, por tratarse de derechos cuyo ejercicio es enteramente personal, salvo que la ley o una orden judicial establezca lo contrario.</p>

        <p className="aboutus__subcontent" >La participación en Fidelogist.com implica la aceptación de sus condiciones generales vigentes.</p>
        
    </div>



    );

};

export default AboutUsText;