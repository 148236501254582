import React, { useContext } from "react";
import { Accordion, AccordionContext, Card } from "react-bootstrap";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";

import BannerLegal from "../../shared/components/UIElements/BannerLegal";
import HelpForm from "../components/HelpPageForm";
import HelpPageText from "../components/HelpPageText";
import "./HelpPage.css"


const HelpPage = () =>{

    const ContextAwareToggle = ({ children, eventKey, callback }) => {
        const currentEventKey = useContext(AccordionContext);

        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );

        const isCurrentEventKey = currentEventKey === eventKey;

        return (
            <Card.Header
                className={
                    isCurrentEventKey
                        ? "help-page__faq-accordion--active"
                        : ""
                }
                onClick={decoratedOnClick}
            >
                <div className="d-flex justify-content-between">
                    <div>{children}</div>
                    <div className={isCurrentEventKey ? "help-page__faq-accordion--inactive" : "help-page__faq-accordion--active"}>{isCurrentEventKey ? "-" : "+"}</div>
                </div>
            </Card.Header>
        );
    };

    return (
        <div>
            <BannerLegal title='Ayuda' />
            <Accordion className="help-page__faq-accordion">
                <h3 className="help-page__faq-accordion-title">
                    Preguntas frecuentes:
                </h3>
                <Card>
                    <ContextAwareToggle eventKey="0">
                        ¿Qué es Fidelogist?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>Con Fidelogist.com ganas Gift Card en tu día a día para que los uses al instante donde quieras.
Puedes ganar Gift Card en tu lugar de trabajo como incentivo por cumplir metas, entrenamientos, encuestas, aguinaldos y más.
También ganas Gift Card Fidelogist en tus compras de supermercados y tiendas favoritas, aumentando de manera inmediata entre 3 y 8 veces los beneficios que te entregan sus actuales programas de Gift Card.
Con Fidelogist es mucho más fácil y rápido canjear tus Gift Card, ya que los puedes utilizar desde tu teléfono en más de 3.500 tiendas a lo largo de todo el país, de manera inmediata y sin restricciones.
Trabajamos día a día apasionadamente para que todo lo que hagas en Fidelogist.com sea simple y entretenido, para que puedas ganar y canjear más en todo lo que hagas.</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="1">
                        ¿Cómo funciona?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>El acceso y uso de este sitio web se rige por los términos y condiciones descritos a continuación, así como por la legislación que se aplique en la República de Chile. En consecuencia, todas las visitas y todos los contratos y transacciones que se realicen en este sitio, como asimismo sus efectos jurídicos, quedarán regidos por estas reglas y sometidas a esa legislación.

La participación en Fidelogist.com implica la aceptación de sus condiciones generales vigentes.

Los términos y condiciones contenidos en este instrumento se aplicarán y se entenderán forman parte de todos los actos y contratos que se ejecuten o celebren mediante los sistemas de canje comprendidos en este sitio web entre los usuarios de este sitio, las empresas y programas de Gift Card asociados a Fidelogist.com, y por cualquiera de las otras sociedades o empresas que sean filiales o coligadas con ellas, y que hagan uso de este sitio.

Fidelogist.com, podrá introducir modificaciones a las presentes bases, las que comunicará a los Usuarios con, al menos, 15 días hábiles de anticipación a la fecha de su vigencia, las que se entenderán aceptadas por los Usuarios si realizan alguna transacción o utilizan algunos de los beneficios de Fidelogist.com.

Un texto actualizado de las presentes bases se mantendrá en el sitio web de Fidelogist.com</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="2">
                        Transferencia de Gift Card Fidelogist
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>Los términos relativos a los cargos, condiciones, funcionamiento y responsabilidades aplicables a la transferencia de Gift Card Fidelogist serán definidos exclusivamente en los términos y condiciones que se informarán a los Usuarios al momento de acceder a la plataforma en línea de transferencia, y que los Usuarios deberán leer y aceptar antes de realizar la transacción..</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="3">
                        ¿Cómo sé que canje correctamente?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                        <Card.Body>Los Gift Card Fidelogist que el Usuario agrupe en su Cuenta, podrán ser utilizados para canjearlos en línea por bienes y/o servicios a través de las opciones disponibles para ello. Los Gift Card Fidelogist utilizados por el Usuario para canjear un bien o servicio ofrecido serán descontados de la Cuenta del Usuario, al precio vigente equivalentes en Gift Card del bien y/o servicio al momento de canje. En todo caso tratándose de los servicios, la Empresa podrá aplicar ciertas restricciones, las que serán debidamente comunicadas al Usuario</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="4">
                        ¿Cuanto duran mis Gift Card?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>La vigencia de los Gift Card Fidelogist es de 24 meses contados desde la fecha de la recepción de los Gift Card Fidelogist. Los Gift Card Fidelogist no canjeados al finalizar dicho periodo caducarán y serán descontados de la respectiva Cuenta. El Usuario sólo podrá canjear sus Gift Card Fidelogist dentro del plazo antes indicado por el bien o servicio de su elección, considerando el puntaje mínimo establecido para canje. Fidelogist.com se reserva el derecho de modificar total o parcialmente la vigencia de los Gift Card Fidelogist, asumiendo sólo el compromiso de anunciarlo adecuadamente a los Usuarios mediante los canales que estime convenientes. Los Gift Card asignados por las empresas a los usuarios que no se han registrado en la plataforma poseen una vigencia de 12 meses, al finalizar dicho periodo caducarán y serán descontados de la respectiva Cuenta</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="5">
                        ¿Que puedo hacer con mis Gift Card?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>No tienen ningún valor comercial, no siendo negociables ni canjeables por dinero.
                        Son transferibles, de acuerdo a lo establecido en el número 5 del presente Reglamento</Card.Body>
                    </Accordion.Collapse>
                </Card>
                <Card>
                    <ContextAwareToggle eventKey="6">
                        Propiedad de información
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="6">
                        <Card.Body>Toda la información de este sitio es propiedad de Fidelogist.COM, por lo tanto, no se puede modificar, copiar, extraer o utilizar de otra manera sin el consentimiento expreso previo y por escrito. Por lo tanto, al acceder a www.Fidelogist.com el usuario es consciente de que el mal uso de esta información puede resultar en sanciones civiles y penales.</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            <div className="help-page__info--container">
                <HelpPageText />
                <HelpForm />
            </div>
            
        </div>
    );

};

export default HelpPage;