import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../shared/context/AuthContext";
import { Redirect } from "react-router-dom";

import OpportunityContainer from "../components/OpportunityContainer";
import BannerHome from "../components/BannerHome";
import ScrollContainer from "../../shared/components/UIElements/ScrollContainer.jsx";
import ComoFunciona from "../components/ComoFunciona.jsx";

import { ProductsContext } from "../../shared/context/ProductsContext";
import { useHttpClient } from "../../shared/hooks/http-hook";

// import { DateTime } from "luxon";

// const DUMMY_WINNERS = [
//     {
//         id: 1,
//         user: {
//             firstName: "Lucía",
//             lastName: "Viano",
//             email: "lucia*****@gmail.com",
//             image: "uploads/default/default-profile.jpg",
//         },
//         product: {
//             title: "Viaje a Ámsterdam",
//             imageUrl: ["uploads/default/seeds/product/1.jpg"],
//             lotteryDate: DateTime.now().toISO(),
//         },
//         ticketsBought: 10,
//         chance: 20,
//     },
//     {
//         id: 2,
//         user: {
//             firstName: "Ernesto",
//             lastName: "Peralta",
//             email: "ernesto*****@yahoo.com",
//             image: "uploads/default/default-profile.jpg",
//         },
//         product: {
//             title: "Celular Samsung",
//             imageUrl: ["uploads/default/seeds/product/2.jpg"],
//             lotteryDate: DateTime.now().toISO(),
//         },
//         ticketsBought: 100,
//         chance: 30,
//     },
//     {
//         id: 3,
//         user: {
//             firstName: "Julieta",
//             lastName: "Nastari",
//             email: "juli*****@outlook.com",
//             image: "uploads/default/default-profile.jpg",
//         },
//         product: {
//             title: "Cena para 2",
//             imageUrl: ["uploads/default/seeds/product/3.jpg"],
//             lotteryDate: DateTime.now().toISO(),
//         },
//         ticketsBought: 5,
//         chance: 3,
//     },
// ];

const Home = (props) => {
    const auth = useContext(AuthContext);
    const [loggedUser, setLoggedUser] = useState(null);

    const { products } = useContext(ProductsContext);
    const [loadedMainSliders, setLoadedMainSliders] = useState();
    const [loadedCategories, setLoadedCategories] = useState();
    const [loadedWinners, setLoadedWinners] = useState();
    const [loadedProductsClosed, setloadedProductsClosed] = useState();
    const { isLoading, sendRequest } = useHttpClient();

    useEffect(() => {
        const fetchMainSliders = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/mainsliders`
                );
                setLoadedMainSliders(responseData.mainsliders);
            } catch (err) {}
        };
        fetchMainSliders();
    }, [sendRequest]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/categories`
                );
                setLoadedCategories(responseData.categories);
            } catch (err) {}
        };
        fetchCategories();
    }, [sendRequest]);

    useEffect(() => {
        const fetchWinners = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/winners`
                );
                setLoadedWinners(responseData.winners);
            } catch (err) {}
        };
        fetchWinners();
    }, [sendRequest]);

    useEffect(() => {
        const fetchProductsClosed = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/products/closed`
                );
                setloadedProductsClosed(responseData.products);
            } catch (err) {}
        };
        fetchProductsClosed();
    }, [sendRequest]);
    
    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [auth.loggedUserData]);
    return (
       
        <React.Fragment>
            {auth.isLoggedIn && loggedUser && loggedUser.type === "admin" && ( 
                <Redirect to="/backend" />
            )}
            {!isLoading && loadedMainSliders && (
                <BannerHome items={loadedMainSliders} />
            )}
            <OpportunityContainer />
            {!isLoading && loadedCategories && (
                <ScrollContainer
                    items={loadedCategories}
                    title="Categorías."
                    scroll="220"
                    verMasText="Ver todas"
                    verMasRef="/"
                    cardType="CategoryCard"
                />
            )}

            {!isLoading && products && (
                <ScrollContainer
                    items={products}
                    title="Los más canjeados."
                    scroll="700"
                    verMasText="Ver todos"
                    verMasRef="/"
                    cardType="ProductCard"
                />
            )}
            {!isLoading && products && (
                <ScrollContainer
                    items={products}
                    title="Destacados."
                    scroll="700"
                    verMasText="Ver más"
                    verMasRef="/"
                    filters="true"
                    cardType="ProductCard"
                />
            )}
            <ComoFunciona />
            {!isLoading && loadedProductsClosed &&(<ScrollContainer
                items={loadedProductsClosed}
                title="Últimos Canjes."
                scroll="700"
                cardType="ProductCard"
            />)}
            {!isLoading && loadedWinners &&(<ScrollContainer
                items={loadedWinners}
                title="Fidelogist Reseñas."
                scroll="700"
                cardType="WinnerCard"
            />)}
        </React.Fragment>
    );
};

export default Home;
