import React, { createContext, useReducer, useContext } from "react";
import { CartReducer, sumItems } from "./CartReducer";

import { AuthContext } from "../../shared/context/AuthContext";
import { useHttpClient } from "../../shared/hooks/http-hook";

export const CartContext = createContext();

const storage = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
const initialState = {
    cartItems: storage,
    ...sumItems(storage),
    checkout: false,
};

const CartContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CartReducer, initialState);
    const auth = useContext(AuthContext);
    const { sendRequest } = useHttpClient();

    const increase = (payload) => {
        dispatch({ type: "INCREASE", payload });
    };

    const decrease = (payload) => {
        dispatch({ type: "DECREASE", payload });
    };

    const addProduct = (payload) => {
        dispatch({ type: "ADD_ITEM", payload });
    };

    const removeProduct = (payload) => {
        dispatch({ type: "REMOVE_ITEM", payload });
    };

    const clearCart = () => {
        dispatch({ type: "CLEAR" });
    };

    const handleCheckout = async () => {
        try {
            const responseDataMail = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/mails/sendmail`,
                "POST",
                JSON.stringify({
                    firstName: auth.loggedUserData.firstName,
                    email: auth.loggedUserData.email,
                    message: "Hola "+auth.loggedUserData.firstName,
                }),
                {
                    "Content-Type": "application/json",
                }
            );
            if (responseDataMail.approved.accepted[0].status === 'sent'){
                //console.log(responseData.userId,responseData.token);
               console.log("mail enviado");
            }
            
            
        } catch (err) {}
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/orders/`,
                "POST",
                JSON.stringify({
                    purchases: state.cartItems,
                    total: state.total,
                    userId: auth.userId,
                }),
                {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + auth.token,
                }
            );
             auth.loggedUserData.monto=responseData.montofinal;
             console.log(responseData.link);
             dispatch({ type: "CHECKOUT" });
             window.location=responseData.link;
             
        } catch (err) {
            // hacer error visible
            console.log(err);
        }
        
    };

    const contextValues = {
        removeProduct,
        addProduct,
        increase,
        decrease,
        clearCart,
        handleCheckout,
        ...state,
    };

    return (
        <CartContext.Provider value={contextValues}>
            {children}
        </CartContext.Provider>
    );
};

export default CartContextProvider;
