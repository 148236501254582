import React from "react";

import "./ProductCard.css";
import Button from "../FormElements/Button.jsx";
import Badge from "../UIElements/Badge.jsx";

// const DUMMY_PRODUCT_EXAMPLE = {
//     id: "1",
//     title: "Titulo de producto",
//     category: "Categoría de producto",
//     description: "Descripción de producto",
//     imageUrl: "Espera una URL, a cambiar o definir luego",
//     price: "precio",
//     tickets: "número de tickets en Integer",
//     maxTickets: "número máximo de tickets en Integer",
//     status: "active o inactive dependiendo de si está completado o no",
// };

const ProductCard = (props) => {
    return (
        <div className="product-card">
            <Badge status={props.status} className="product-card__badge">
                {props.status === "active" ? "Activo" : ""}
                {props.status === "closed" ? "Canjeado" : ""}
                {props.status === "winned" ? "Winned" : ""}
            </Badge>
            <img alt="" src={`${process.env.REACT_APP_API_URL}/${props.imageUrl[0]}`} className="product-card__img" />
            <div className="product-card__content">
                <div className="product-card__info-top">
                    <h4 className="product-card__title">{props.title}</h4>
                    <p className="product-card__description">
                        {props.shortDescription}
                    </p>
                </div>
                <div className="product-card__info-mid">
                    <p
                        className={`product-card__candidates product-card__candidates--${props.status}`}
                    >
                        Stock:{" "} 
                            {Math.floor(
                                (props.maxTickets - props.tickets ) 
                            )}
                            {" "}
                        
                        <span className="product-card__percentage">
                            {" "}
                            (
                            {Math.floor(
                                (1-(props.tickets / props.maxTickets)) *
                                    100
                            )}
                            %)
                        </span>
                    </p>
                    <p className="product-card__pricing">$ {props.price}</p>
                </div>
                <div className="product-card__info-bot">
                    <Button status={props.status} to={`../products/${props.id}`}>{props.status === "active" ? "Lo quiero!" : "Ver"}</Button>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
