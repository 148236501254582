import React from "react";

import './PartnersPageText.css'

const PartnersPageText = () =>{

    return(
    <div className="partners-page__content">
        <h2 className="partners-page__title">¿Quieres ser parte de Fidelogist?</h2>
        <p className="partners-page__subcontent" >Invierte tu presupuesto de marketing directamente en tus clientes, con acciones 100% medibles y con una alta flexibilidad. Prueba los módulos que necesites con el presupuesto que tengas disponible. Además gracias a Fidelogist podrás visualizar el retorno de tu presupuesto asignado a las distintas acciones.</p>
        
        
    </div>



    );

};

export default PartnersPageText;