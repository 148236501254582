import { useState, useCallback, useEffect } from "react";

let logoutTimer;

export const useAuth = () => {
    const [token, setToken] = useState(false);
    const [tokenExpirationDate, setTokenExpirationDate] = useState();
    const [userId, setUserId] = useState(false);
    const [loggedUserData, setLoggedUserData] = useState(false);

    const login = useCallback((userData, expirationDate) => {
        const loggedUser = {
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            telephone: userData.telephone,
            type: userData.type,
            monto: userData.monto,
            image: userData.image,
        };
        setToken(userData.token);
        setUserId(userData.userId);
        setLoggedUserData(loggedUser);
        const tokenExpirationDate =
            expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60);
        setTokenExpirationDate(tokenExpirationDate);
        localStorage.setItem(
            "userData",
            JSON.stringify({
                userId: userData.userId,
                token: userData.token,
                email: userData.email,
                firstName: userData.firstName,
                lastName: userData.lastName,
                type: userData.type,
                monto: userData.monto,
                telephone: userData.telephone,
                image: userData.image,
                expiration: tokenExpirationDate.toISOString(),
            })
        );
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        setTokenExpirationDate(null);
        setUserId(null);
        setLoggedUserData(null);
        localStorage.removeItem("userData");
    }, []);

    useEffect(() => {
        if (token && tokenExpirationDate) {
            const remainingTime =
                tokenExpirationDate.getTime() - new Date().getTime();
            logoutTimer = setTimeout(logout, remainingTime);
        } else {
            clearTimeout(logoutTimer);
        }
    }, [token, logout, tokenExpirationDate]);

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem("userData"));
        if (
            storedData &&
            storedData.token &&
            new Date(storedData.expiration) > new Date()
        ) {
            login(storedData, new Date(storedData.expiration));
        }
    }, [login]);

    return { token, login, logout, userId, loggedUserData };
};
