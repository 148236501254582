import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import "./ProductPage.css";
import BannerProduct from "../components/BannerProduct";
import ProductInfo from "../components/ProductInfo";
import ProductImageCarrusel from "../components/ProductImageCarrusel";
import ProductMoreInfo from "../components/ProductMoreInfo";
import ScrollContainer from "../../shared/components/UIElements/ScrollContainer";
import GenericModal from "../../shared/components/UIElements/GenericModal";

import { ProductsContext } from "../../shared/context/ProductsContext";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/AuthContext";
//import ProductPurchased from "../components/ProductPurchased";

const ProductPage = () => {
    const { products, isLoading } = useContext(ProductsContext);
    const auth = useContext(AuthContext);

    const [identifiedProduct, setIdentifiedProduct] = useState(null);
    const [userProductInfo, setUserProductInfo] = useState(null);
    const [loggedUser, setLoggedUser] = useState(null);
    const [participants, setParticipants] = useState(null);

    const { sendRequest, error, clearError } = useHttpClient();

    const productId = useParams().productId;

    const productPageFetch = useCallback(async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/products/${productId}/${auth.userId}`,
                "GET",
                null,
                { Authorization: "Bearer " + auth.token }
            );
            setIdentifiedProduct(responseData.product);
            setParticipants(responseData.participants);
            setUserProductInfo(responseData.userProductInfo);
        } catch (err) {}
    }, [sendRequest, auth.userId, productId, auth.token]);

    useEffect(() => {
        productPageFetch();
    }, [productPageFetch, loggedUser]);

    useEffect(() => {
        setLoggedUser(auth.loggedUserData);
    }, [auth.loggedUserData]);

    if (
        !isLoading &&
        participants &&
        userProductInfo &&
        products &&
        identifiedProduct
    ) {
        return (
            <React.Fragment>
                <GenericModal
                    message={error}
                    show={!!error}
                    error={true}
                    title={"Ocurrió un error"}
                    onClear={clearError}
                />
                <BannerProduct
                    category={identifiedProduct.category.title}
                    title={identifiedProduct.title}
                />
                <div className="proudct-page__container">
                    <div>
                        <ProductImageCarrusel
                            images={identifiedProduct.imageUrl}
                            title={identifiedProduct.title}
                        />
                    </div>
                    <div>
                        <ProductInfo
                            key={identifiedProduct.id}
                            {...identifiedProduct}
                        />
                    </div>
                </div>
                {/*userProductInfo.purchased && loggedUser && participants && (
                    <ProductPurchased
                        participants={participants}
                        product={identifiedProduct.id}
                        title={identifiedProduct.title}
                        winner={identifiedProduct.winner}
                        isWinner={userProductInfo.isWinner}
                        userName={loggedUser.firstName}
                        tickets={userProductInfo.tickets}
                        lotteryDate={identifiedProduct.lotteryDate}
                        probability={userProductInfo.probability}
                        productStatus={identifiedProduct.status}
                    />
                )*/}
                <ProductMoreInfo {...identifiedProduct} />
                <ScrollContainer
                    items={products}
                    title="Nuevos."
                    scroll="700"
                    verMasText="Ver más"
                    verMasRef="/"
                    cardType="ProductCard"
                />
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default ProductPage;
