import React from "react";

import Button from "../../shared/components/FormElements/Button.jsx";
import Badge from "../../shared/components/UIElements/Badge";
import {
  BiHistory,
  BiPurchaseTag,
  BiCalendarEvent,
  BiGift,
} from "react-icons/bi";
import "./PurchaseCard.css";

const PurchaseCard = (props) => {

  
  const getInfoProduct = (buyProductId) => {

    const identifiedProduct = props.products.find((p) => p._id === buyProductId);

    return identifiedProduct;
  };

  const ProductItem = getInfoProduct(props.item.product);

  return (
    <div className="user-purchases-card">
      <Badge status={ProductItem.status} className="user-purchases-card__badge">
        {ProductItem.status === "active" ? "Activo" : ""}
        {ProductItem.status === "closed" ? "Canjeado" : ""}
        {ProductItem.status === "winned" ? "Rifado" : ""}
        {ProductItem.status === "expired" ? "Vencido" : ""}
      </Badge>
      <img
        alt=""
        src={`${process.env.REACT_APP_API_URL}/${ProductItem.imageUrl[0]}`}
        className="user-purchases-card__img"
      />
      <div className="user-purchases-card__content">
        <div>
          <h4 className="user-purchases-card__title">{ProductItem.title}</h4>
        </div>
        <div className="user-purchases-card__info-mid">
          <BiHistory size={30} />{" "}
          <p
            className={`user-purchases-card__candidates user-purchases-card__candidates--${ProductItem.status}`}
          >
            <span className="user-purchases-card__candidates__progress">
              Stock <br />
            </span>{" "}
            Stock:{" "} {Math.floor(
                                (ProductItem.maxTickets - ProductItem.tickets ) 
                            )}
            <span className="user-purchases-card__percentage">
              {" "}
              (
              {Math.floor((1-(ProductItem.tickets / ProductItem.maxTickets)) * 100)}
              %)
            </span>
          </p>
        </div>
        <div className="user-purchases-card__info-mid">
          <BiPurchaseTag size={30} />{" "}
          <p
            className={`user-purchases-card__candidates user-purchases-card__tickets`}
          >
            <span className="user-purchases-card__candidates__progress">
              Mis Unidades <br />
            </span>
            Compraste {props.item.quantity}{" "}
            {props.item.quantity !== 1 ? "unidades" : "unidad"}
          </p>
        </div>

        {ProductItem.status === "closed" ? (
            <div className="user-purchases-card__info-mid">
              <BiCalendarEvent size={30} />{" "}
              <p
                className={`user-purchases-card__candidates user-purchases-card__tickets`}
              >
                <span className="user-purchases-card__candidates__progress">
                  El canje se realizará el
                  <br />
                </span>
                {ProductItem.lotterydate}
              </p>
            </div>
          ) : ""} 
          {ProductItem.status === "winned" ? (
            <div>
              {" "}
              <div className="user-purchases-card__info-mid">
                <BiCalendarEvent size={30} />{" "}
                <p
                  className={`user-purchases-card__candidates user-purchases-card__tickets`}
                >
                  <span className="user-purchases-card__candidates__progress">
                    El canje se realizó el <br />
                  </span>
                  {ProductItem.lotterydate}
                </p>
              </div>
              <div className="user-purchases-card__info-mid">
                {" "}
                <BiGift size={30} />
                <p
                  className={`user-purchases-card__candidates user-purchases-card__tickets`}
                >
                  <span className="user-purchases-card__candidates__progress">
                    El/La ganadadora fue: <br />
                  </span>
                  {ProductItem.winner.email}
                </p>
              </div>{" "}
            </div>
          ) : 
          ""
        }

        <div className="user-purchases-card__info-bot">
          <Button status="active" to={`../../products/${ProductItem._id}`}>
            Ver más!
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PurchaseCard;
