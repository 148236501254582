import React, { useContext } from "react";
import { NavDropdown } from "react-bootstrap";

import { CartContext } from "../../context/CartContext";

import CartItemNav from "./CartItemNav";
import Button from "../FormElements/Button";

import "./CartProductsContainerNav.css";

const CartProductsContainerNav = () => {
    const { cartItems, total } = useContext(CartContext);

    if (cartItems.length === 0) {
        return (
            <div className="cart-nav__products">
                    Tu carrito está vacío
            </div>
        );
    }

    return (
        <div className="cart-nav__products">
            {cartItems.length > 0 &&
                cartItems.map((product) => (
                    <CartItemNav key={product.id} product={product} />
                ))}
            <div className="cart-nav__totals">
                <div className="cart-nav__total-text">Total</div>
                <div className="cart-nav__total-price">$ {total}</div>
            </div>

            <NavDropdown.Item as="div" className="cart-nav__button-container">
                <Button
                    to="/cart"
                    status="active"
                    className="main-button--squared text-center main-button--full-width"
                >
                    Ir Al Carrito
                </Button>
            </NavDropdown.Item>
        </div>
    );
};

export default CartProductsContainerNav;
