import React from "react";

import './TermsConditions.css'

const TermsConditions = () =>{

    return(
    <div className="legal__content">
        <h3 className="legal__title">Política de gestión de Calidad</h3>
        <p className="legal__subcontent" >Los términos y condiciones contenidos en este instrumento se aplicarán y se entenderán forman parte de todos los actos y contratos que se ejecuten o celebren mediante los sistemas de canje comprendidos en este sitio web entre los usuarios de este sitio, las empresas y programas de Gift Card asociados a Fidelogist.com, y por cualquiera de las otras sociedades o empresas que sean filiales o coligadas con ellas, y que hagan uso de este sitio.</p>
        <p className="legal__subcontent" >Fidelogist.com, podrá introducir modificaciones a las presentes bases, las que comunicará a los Usuarios con, al menos, 15 días hábiles de anticipación a la fecha de su vigencia, las que se entenderán aceptadas por los Usuarios si realizan alguna transacción o utilizan algunos de los beneficios de Fidelogist.com.</p>
        <h3 className="legal__title">Política de Privacidad de Fidelogist</h3>
        <h4 className="legal__sub-title">I. Objeto</h4>
        <p className="legal__subcontent" >La Cuenta es de uso personal y Fidelogist.com establecerá los mecanismos de seguridad para su acceso, tales como claves secretas u otros sistemas de seguridad. El Usuario debe mantener bajo su control la información de acceso a su Cuenta, siendo responsable del uso de su información se haga por terceros con su información secreta de acceso y/o mecanismo de seguridad que se encuentre bajo su control. Se deja expresa constancia que Fidelogist.com no se hará responsable de fraudes o canjes no autorizados realizados con la contraseña del Usuario o con modificaciones o alteraciones de la misma que se realicen sin el consentimiento del Usuario.</p>
        <h4 className="legal__sub-title">II. Definición de Terminos y Condiciones</h4>
        <p className="legal__subcontent" >Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aut saepe dicta ipsam deserunt ut explicabo recusandae itaque esse, earum fuga et consectetur rerum magni aspernatur. Blanditiis quis adipisci eos voluptates.</p>
        <h4 className="legal__sub-title">III. Principios rectores para el tratamiento de datos</h4>
        <p className="legal__subcontent" >Solo se permitirá una cuenta por Titular. En caso de existir dos o más Cuentas, las más recientes serán canceladas. Todos los Gift Card Fidelogist acumulados en las Cuentas canceladas se traspasarán a la Cuenta original, siempre y cuando dichos Gift Card Fidelogist no hayan sido acumulados en ambas Cuentas simultáneamente.</p>
        <h4 className="legal__sub-title">IV. Cesión de datos y transferencia internacional.</h4>
        <p className="legal__subcontent" >Los términos relativos a los cargos, condiciones, funcionamiento y responsabilidades aplicables a la transferencia de Gift Card Fidelogist serán definidos exclusivamente en los términos y condiciones que se informarán a los Usuarios al momento de acceder a la plataforma en línea de transferencia, y que los Usuarios deberán leer y aceptar antes de realizar la transacción.</p>
        <h4 className="legal__sub-title">V. Canjes de Bienes y Servicios</h4>
        <p className="legal__subcontent" >Los Gift Card Fidelogist que el Usuario agrupe en su Cuenta, podrán ser utilizados para canjearlos en línea por bienes y/o servicios a través de las opciones disponibles para ello. Los Gift Card Fidelogist utilizados por el Usuario para canjear un bien o servicio ofrecido serán descontados de la Cuenta del Usuario, al precio vigente equivalentes en Gift Card del bien y/o servicio al momento de canje. En todo caso tratándose de los servicios, la Empresa podrá aplicar ciertas restricciones, las que serán debidamente comunicadas al Usuario.</p>
    </div>



    );

};

export default TermsConditions;