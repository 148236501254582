import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import AuthModal from "../../user/components/AuthModal";

import "./BlankPage.css";

const BlankPage = (props) => {
    const history = useHistory();

    const handleRedirect = () => {
        let url = `/`;
        history.push(url);
    };

    const [isModalLogin, setIsModalLogin] = useState(true);

    return (
        <React.Fragment>
            <AuthModal
                show={props.modal}
                redirect={handleRedirect}
                isModalLogin={isModalLogin}
                title={
                    isModalLogin
                        ? "Inicia Sesión para Continuar"
                        : "¡Registrate!"
                }
                setIsModalLogin={setIsModalLogin}
            />
            <div className="blank-page"></div>;
        </React.Fragment>
    );
};

export default BlankPage;
