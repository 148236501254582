import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";

import UsersList from "../components/UsersList";
import GenericModal from "../../../shared/components/UIElements/GenericModal";

import Button from "../../../shared/components/FormElements/Button";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import * as Yup from "yup";
import { AuthContext } from "../../../shared/context/AuthContext";
import "./Users.css";

const HelpSchema = Yup.object().shape(
    {
      csv: Yup.mixed().required("Requerido"),
    }
  );

const Users = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedUsers, setLoadedUsers] = useState();
    const [loggedUser, setLoggedUser] = useState(null);
    const [userrefresh, setUsersRefresh] = useState(false);

    const handleShowSignup = () => {
        const link = document.createElement("a");
        link.href = `${process.env.REACT_APP_API_URL}/uploads/images/Reporteria.xlsx`;
        //link.setAttribute('download',`${stand._id}.xlsx`,);
        link.download = `Reporteria.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/users`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedUsers(responseData.users);
                setUsersRefresh(false);
            } catch (err) {}
        };
        fetchUsers();
    }, [sendRequest, userrefresh, auth.token]);

    useEffect(() => {
        const fetchUser = async () => {
            if (auth.userId) {
                try {
                    const responseData = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/users/${auth.userId}`,
                        "GET",
                        null,
                        { Authorization: "Bearer " + auth.token }
                    );
                    setLoggedUser(responseData.user);
                } catch (err) {}
            } else {
                setLoggedUser(null);
            }
        };
        fetchUser();
    }, [auth.login, auth.logout, auth.userId, auth.token, sendRequest]);

    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {isLoading && (
                <div className="center">
                    <LoadingSpinner />
                </div>
            )}
            <BannerCategory title="Administración de Usuarios" />
            <div className="analytics__header__button">
                    <Button status="active" onClick={handleShowSignup}>
                        Descargar Reporteria
                    </Button>
            </div>
            <Formik
        initialValues={{
          csv: "",
        }}
        validateOnMount
        validationSchema={HelpSchema}
        handleChange
        onSubmit={async (values) => {
          try {
            const formData = new FormData();
            formData.append("csv", values.csv);
            await sendRequest(
              `${process.env.REACT_APP_API_URL}/api/users/import`,
              "POST",
              formData,
            );
          } catch (err) {}
        }}
      >
        {(formProps) => (
            <Form className="analytics-form">
            <input
                type="file"
                name="csv"
                onChange={(event) => {
                    formProps.setFieldValue(
                    "csv",
                    event.target.files[0]
                    );
                }}
                />
            <Button
                        className="main-button--squared-bigger"
                        type="submit"
                        status="active"
                      >
                        Importar
                      </Button>
                </Form>
            )}
            </Formik>
            {!isLoading && loadedUsers && (
                <UsersList
                    userslist={loadedUsers}
                    user={loggedUser}
                    refresh={setUsersRefresh}
                />
            )}
        </React.Fragment>
    );
};

export default Users;
