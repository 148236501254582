import React, { useState, useEffect }from "react";
import { Link } from "react-router-dom";

import { FaFacebookF, FaInstagram } from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import { useHttpClient } from "../../hooks/http-hook";

import "./Footer.css";

import Button from "../FormElements/Button.jsx";

import footerlogo from "../../assets/logo.png";

const Footer = (props) => {

    const { sendRequest } = useHttpClient();
    const [loadedCategories, setLoadedCategories] = useState();
    
    const ScrollUp = () => {
       
          window.scrollTo(0, 0);
      
        return null;
      }
    useEffect(() => {
        const fetchCategories = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_API_URL}/api/categories`
            );
            setLoadedCategories(responseData.categories);
          } catch (err) {}
        };
        fetchCategories();
      }, [sendRequest]);
    return (
        <React.Fragment>
            <div className="footer">
                <div className="footer-blocks">
                    <div className="footer--block-divider">
                        <div className="footer__top-block">
                            <div className="footer__logo">
                                <Link to="/">
                                    <img src={footerlogo} alt="" />
                                </Link>
                                <p>Canjea</p>
                                <p className="footer__logo--highlighted">
                                    Regala giftcards
                                </p>
                                <p>Disfruta tus canjes</p>
                            </div>
                            <div className="footer-content-wrapper">
                                <div className="footer-content__section">
                                    <h3 className="footer-content__title">
                                        Categorías
                                    </h3>
                                    {loadedCategories && (loadedCategories.map((items,index) => {
                                        return (
                                            <Link
                                                to={{
                                                    pathname: "/products",
                                                    state: {
                                                        categoryRoute: items.title,
                                                        priceRoute: "Desde $0",
                                                        progressRoute: "Desde 0%",
                                                    },
                                                }}
                                                onClick={ScrollUp}
                                                key={index + 1}
                                                className="footer-content__nav-link footer-content__nav-link--block"
                                            >
                                                {items.title}
                                            </Link>
                                        );
                                    }))}                                    
                                </div>
                                <div className="footer-content__section">
                                    <h3 className="footer-content__title">
                                        Ayuda
                                    </h3>
                                    <Link
                                        to="/help"
                                        onClick={ScrollUp}
                                        className="footer-content__nav-link footer-content__nav-link--block"
                                    >
                                        Contacto
                                    </Link>
                                    <Link
                                        to="/help"
                                        onClick={ScrollUp}
                                        className="footer-content__nav-link footer-content__nav-link--block"
                                    >
                                        Preguntas frecuentes
                                    </Link>
                                    <Link
                                        to="/help"
                                        onClick={ScrollUp}
                                        className="footer-content__nav-link footer-content__nav-link--block"
                                    >
                                        Formas de canjear 
                                    </Link>
                                </div>
                                <div className="footer-content__section">
                                    <h3 className="footer-content__title">
                                    Fidelogist
                                    </h3>
                                    <Link
                                        to="/aboutus"
                                        onClick={ScrollUp}
                                        className="footer-content__nav-link footer-content__nav-link--block"
                                    >
                                        ¿Qué es Fidelogist?
                                    </Link>
                                    <Link
                                        to="/partners"
                                        onClick={ScrollUp}
                                        className="footer-content__nav-link footer-content__nav-link--block"
                                    >
                                        Conviértete en partner
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__bottom-block">
                        <Dropdown className="footer--mobile-margin">
                            <Dropdown.Toggle className="footer__country-dropdown footer__country-dropdown--styling shadow-none">
                                <div className="footer__country-dropdown--title">
                                    Chile
                                </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    className="footer__country-dropdown--styling"
                                    href="#/action-1"
                                >
                                    Chile
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="footer__country-dropdown--styling"
                                    href="#/action-2"
                                >
                                    Argentina
                                </Dropdown.Item>
                                <Dropdown.Item
                                    className="footer__country-dropdown--styling"
                                    href="#/action-3"
                                >
                                    Brasil
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="footer--mobile-margin">
                            <Link
                                to="/legal"
                                onClick={ScrollUp}
                                className="footer-content__nav-link footer-content__nav-link--small footer--right-margin"
                            >
                                Políticas y Garantías
                            </Link>
                            <Link
                                to="/legal"
                                onClick={ScrollUp}
                                className="footer-content__nav-link footer-content__nav-link--small"
                            >
                                Términos y Condiciones
                            </Link>
                        </div>
                        <div className="footer__button-container footer--mobile-margin">
                            <Button
                                className="main-button--squared main-button--small footer--right-margin"
                                status="active"
                            >
                                Devolver mi pedido
                            </Button>
                            <Button
                                to="/partners"
                                onClick={ScrollUp}
                                className="main-button--squared main-button--small main-button--inverse"
                                status="active"
                            >
                                Partners
                            </Button>
                        </div>
                        <div className="footer--mobile-margin">
                            <a
                                href="https://www.instagram.com"
                                className="footer__networks footer--right-margin"
                            >
                                <FaInstagram />
                            </a>
                            <a
                                href="https://www.facebook.com"
                                className="footer__networks"
                            >
                                <FaFacebookF />
                            </a>
                        </div>
                    </div>
                </div>
                <Button to="/help" className="footer__help-button">
                    Necesitas Ayuda? Escribinos
                </Button>
            </div>
            <div className="footer__disclaimer">© Fidelogist | 2023</div>
        </React.Fragment>
    );
};

export default Footer;
