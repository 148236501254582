import React from "react";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import { useHttpClient } from "../../shared/hooks/http-hook";

import * as Yup from "yup";
import "./HelpPageForm.css";

import Button from "../../shared/components/FormElements/Button";

const HelpSchema = Yup.object().shape({
  firstName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
  lastName: Yup.string().max(24, "Demasiado largo").required("Requerido"),
  email: Yup.string().email("Correo inválido").required("Requerido"),
  concern: Yup.string()
    .oneOf(["sorteo", "soporte", "mercadopago", "otro"], "Categoría Inválida")
    .required("Requerido"),
  description: Yup.string().max(300, "Demasiado largo").required("Requerido"),
});

const MyTextArea = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field] = useField(props);
  return (
    <>
      <textarea className="help-form__form-input" {...field} {...props} />
    </>
  );
};
const MySelect = ({ ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field] = useField(props);
  return (
    <>
      <select className="help-form__select-input" {...field} {...props} />
    </>
  );
};

const HelpForm = (props) => {
  const { sendRequest, clearError } = useHttpClient();
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        concern: "",
        description: "",
      }}
      validationSchema={HelpSchema}
      onSubmit={async (values, { setSubmitting }) => {
                clearError();
                try {
                    const responseDataMail = await sendRequest(
                        `${process.env.REACT_APP_API_URL}/api/mails/sendmail`,
                        "POST",
                        JSON.stringify({
                            firstName: values.firstName,
                            email: "jmanuel.martin@onlife.com.ar",
                            message: values.firstName+values.email+"Necesita Ayuda",
                        }),
                        {
                            "Content-Type": "application/json",
                        }
                    );
                    if (responseDataMail.approved === 'sent'){
                        //console.log(responseData.userId,responseData.token);
                       console.log("mail enviado");
                    }
                    
                    
                } catch (err) {}
            }}
    >
      {({ isValid, dirty }) => (
        <div className="help-form__background">
          <h2 className="help-form__title--big">
            Completa los datos, así podemos ayudarte
          </h2>
          <Form className="help-form">
            <div className="help-form__content">
              <div className="help-form__input-block help-form__input-block--grid">
                <div className="help-form__input-block help-form__input-block--column">
                  <div className="help-form__error-placeholder help-form__error-placeholder--column">
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="help-form__validation-error"
                    />
                  </div>
                  <Field
                    type="firstName"
                    name="firstName"
                    placeholder="Nombre"
                    className="help-form__form-input help-form__form-input--inline"
                  />
                </div>
                <div className="help-form__input-block help-form__input-block--column">
                  <div className="help-form__error-placeholder help-form__error-placeholder--column">
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="help-form__validation-error"
                    />
                  </div>
                  <Field
                    type="lastName"
                    name="lastName"
                    placeholder="Apellido"
                    className="help-form__form-input help-form__form-input--inline"
                  />
                </div>
              </div>

              <div className="help-form__input-block">
                <div className="help-form__error-placeholder">
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="help-form__validation-error"
                  />
                </div>
                <Field
                  type="email"
                  name="email"
                  placeholder="Email"
                  className="help-form__form-input "
                />
              </div>

              <div className="help-form__input-block">
                <div className="help-form__error-placeholder">
                  <ErrorMessage
                    name="concern"
                    component="div"
                    className="help-form__validation-error"
                  />
                </div>
                <MySelect name="concern">
                  <option value="">Asunto</option>
                  <option value="sorteo">Gift Card</option>
                  <option value="soporte">Soporte</option>
                  <option value="mercadopago">GiftCards</option>
                  <option value="otro">Otro</option>
                </MySelect>
              </div>
              <div className="help-form__input-block">
                <div className="help-form__error-placeholder">
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="help-form__validation-error"
                  />
                </div>
                <MyTextArea
                  name="description"
                  rows="6"
                  placeholder="Escribe tu inquietud..."
                />
              </div>
            </div>

            <div className="help-form__footer">
              <div className="help-form__button-block--grid">
                <div className="help-form__button-block--column">
                  <Button
                    className="main-button--squared-bigger main-button--80-width"
                    status={dirty && isValid ? "active" : "inactive"}
                    type="submit"
                    onClick={props.hide}
                  >
                    Enviar
                  </Button>
                </div>
                <div className="help-form__button-block--column">
                  <Button
                    className="main-button--squared-bigger main-button--80-width"
                    status={dirty && isValid ? "active" : "inactive"}
                    type="submit"
                    onClick={props.hide}
                  >
                    Whatsapp
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
};

export default HelpForm;
