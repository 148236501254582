import React from "react";

import OpportunityCard from "./OpportunityCard.jsx";
import "./OpportunityContainer.css";

const OPPORTUNITYS = [
    { href: "/products", id: "1", title: "Desde $5000", color: "green", state: { categoryRoute:'Todas', priceRoute: 'Desde $5000', progressRoute:'Desde 0%'} },
    { href: "/products", id: "2", title: "Desde $20.000", color: "purple", state: { categoryRoute:'Todas', priceRoute: 'Desde $20.000', progressRoute:'Desde 0%'}  },
    { href: "/products", id: "3", title: "Desde $50.000", color: "orange", state: { categoryRoute:'Todas', priceRoute: 'Desde $50.000', progressRoute:'Desde 0%'}  },
    { href: "/products", id: "4", title: "Desde $100.000", color: "blue", state: { categoryRoute:'Todas', priceRoute: 'Desde $100.000', progressRoute:'Desde 0%'}  },
    { href: "/products", id: "5", title: "Desde $300.000", color: "yellow", state: { categoryRoute:'Todas', priceRoute: 'Desde $300.000', progressRoute:'Desde 0%'}  },
    { href: "/products", id: "6", title: "Desde $1.000.000", color: "red", state: { categoryRoute:'Todas', priceRoute: 'Desde $1.000.000', progressRoute:'Desde 0%'}  },
];

const OpportunityContainer = () => {
    return (
        <div className="home-opportunities">
            <h3>¡Oportunidades imperdibles!</h3>
            <div className="home-opportunities__items">
                {OPPORTUNITYS.map((opportunity) => {
                    return (

                        <OpportunityCard
                            id={opportunity.id}
                            key={opportunity.id}
                            href={opportunity.href}
                            state={opportunity.state}
                            title={opportunity.title}
                            color={opportunity.color}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default OpportunityContainer;
