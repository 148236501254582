import { React, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./main.css";
import "bootstrap/dist/css/bootstrap.min.css";

import ProductsContextProvider from "./shared/context/ProductsContext";
import CartContextProvider from "./shared/context/CartContext";
import SearchContextProvider from "./shared/context/SearchContext";
import NotificationContextProvider from "./shared/context/NotificationContext";
import { AuthContext } from "./shared/context/AuthContext";
import { LoadingContext } from "./shared/context/LoadingContext";
import { useAuth } from "./shared/hooks/auth-hook";

import ScrollToTop from "./shared/helpers/ScrollToTop";
import LoadingSpinner from "./shared/components/UIElements/LoadingSpinner";

import Routes from "./routes/routes";

const App = () => {
    const { token, login, logout, userId, loggedUserData } = useAuth();
    const [contextIsLoading, setContextIsLoading] = useState(false);

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn: !!token,
                token: token,
                userId: userId,
                loggedUserData: loggedUserData,
                login: login,
                logout: logout,
            }}
        >
            {contextIsLoading && <LoadingSpinner asOverlay />}
            <SearchContextProvider>
                <NotificationContextProvider>
            <ProductsContextProvider>
                <CartContextProvider>
                    <LoadingContext.Provider
                        value={{
                            contextIsLoading,
                            setContextIsLoading,
                        }}
                    >
                        <Router>
                            <ScrollToTop />
                            <Routes />
                        </Router>
                    </LoadingContext.Provider>
                </CartContextProvider>
            </ProductsContextProvider>
            </NotificationContextProvider>
            </SearchContextProvider>
        </AuthContext.Provider>
    );
};

export default App;
