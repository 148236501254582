import React from "react";

import "./ComoFunciona.css";

import iconoDisfrutar from "../assets/IconoDisfrutar.png";
import iconoElegir from "../assets/IconoElegir.png";
import iconoGanar from "../assets/IconoGanar.png";
import iconoPostularse from "../assets/IconoPostularse.png";

const ComoFunciona = () => {
    return (
        <div className="disclaimer-banner">
            <h3 className="disclaimer-banner__title">¿Cómo funciona?</h3>
            <div className="disclaimer-banner__content">
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoElegir}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">
                            Elegir
                        </h4>
                        <p className="disclaimer-banner__step-text">
                            Elige los productos o Gift Cards que quieras.
                        </p>
                    </div>
                </div>
                <i className="disclaimer-banner__arrow"></i>
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoPostularse}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">
                            Canjear
                        </h4>
                        <p className="disclaimer-banner__step-text">
                            Utiliza tus Gift Cards para canjear.
                        </p>
                    </div>
                </div>
                <i className="disclaimer-banner__arrow"></i>
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoGanar}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">Gana</h4>
                        <p className="disclaimer-banner__step-text">
                        Gana Gift Card con tus compras
                        </p>
                    </div>
                </div>
                <i className="disclaimer-banner__arrow"></i>
                <div className="disclaimer-banner__step">
                    <div className="disclaimer-banner__circle">
                        <img
                            className="disclaimer-banner__circle-icon"
                            src={iconoDisfrutar}
                            alt=""
                        />
                    </div>
                    <div className="disclaimer-banner__step-texts">
                        <h4 className="disclaimer-banner__step-title">
                            Disfrutar
                        </h4>
                        <p className="disclaimer-banner__step-text">
                            Disfruta lo que hayas canjeado con tus Gift Card
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ComoFunciona;
