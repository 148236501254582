import React, { useState, useContext } from "react";

import "./OrdersList.css";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import Button from "../../../shared/components/FormElements/Button";
import { Modal } from "react-bootstrap";
import { AuthContext } from "../../../shared/context/AuthContext";
import GenericModal from "../../../shared/components/UIElements/GenericModal";

let iditem;

const OrdersList = (props) => {
    const { sendRequest, error, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const showDeleteWarningHandler = () => {
        setShowConfirmModal(true);
    };

    const cancelDeleteHandler = () => {
        setShowConfirmModal(false);
    };

    const confirmDeleteHandler = async () => {
        setShowConfirmModal(false);

        try {
            await sendRequest(
                `${process.env.REACT_APP_API_URL}/api/orders/${iditem}`,
                "DELETE",
                null,
                { Authorization: "Bearer " + auth.token }
            );
            props.onDeleteOrder(iditem);
        } catch (err) {}
    };

    return (
        <div className="winners-list__container">
            <table className="winners-list__container-table">
                <GenericModal
                    message={error}
                    show={!!error}
                    error={true}
                    title={"Ocurrió un error"}
                    onClear={clearError}
                />
                <Modal
                    show={showConfirmModal}
                    onCancel={cancelDeleteHandler}
                    header="Are you sure?"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Esta seguro?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Quiere borrar esta orden?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            inverse
                            status="active"
                            onClick={cancelDeleteHandler}
                        >
                            CANCELAR
                        </Button>
                        <Button danger onClick={confirmDeleteHandler}>
                            BORRAR
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal></Modal>
                <thead>
                    <tr>
                        <th>Id Canje</th>
                        <th>Id Usuario</th>
                        <th>Nombre</th>
                        <th>Rut</th>
                        <th>Monto</th>
                        <th>Fecha</th>
                        
                        {props.user && props.user.type === "superadmin" && (
                            <th>Action</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.orderslist.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.user.id}</td>
                            <td>{item.user.firstName} {item.user.lastName}</td>
                            <td>{item.user.rut}</td>
                            <td>{item.total}</td>
                            <td>{item.updatedAt}</td>
                           
                            {props.user && props.user.type === "superadmin" && (
                                <td>
                                    <Button
                                        danger
                                        onClick={() => {
                                            iditem = item.id;
                                            showDeleteWarningHandler();
                                        }}
                                    >
                                        BORRAR
                                    </Button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default OrdersList;
