import React, { useEffect, useState, useContext } from "react";
// import Datetime from 'react-datetime';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";

import BannerCategory from "../../../shared/components/UIElements/BannerCategory";
import Select from "../../../shared/components/FormElements/Select";
import GenericModal from "../../../shared/components/UIElements/GenericModal";
import Button from "../../../shared/components/FormElements/Button";
import Card from "../../../shared/components/UIElements/Card";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/AuthContext";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import DateTimePicker from "react-datetime-picker";
import "../components/DraftComponent.css";
import "./ProductListPage.css";

const toolbarProps = {
    colorPicker: {
        default: "var(--main-black)",
        className: undefined,
        component: undefined,
        popupClassName: undefined,
        colors: [
            "var(--main-orange)",
            "var(--secondary-orange)",
            "var(--main-red)",
            "var(--main-black)",
            "var(--grey-1)",
            "var(--grey-2)",
            "var(--grey-3)",
            "var(--btn-purple)",
            "var(--btn-blue)",
            "var(--btn-yellow)",
        ],
    },
    fontFamily: {
        default: ["Poppins"],
        options: ["Poppins"],
        className: "sans-serif",
        component: undefined,
        dropdownClassName: undefined,
    },
};

const HelpSchema = Yup.object().shape({
    title: Yup.string().required("Requerido"),
    // category: Yup.string().required("Requerido"),
    imageUrl: Yup.mixed(),
    price: Yup.string().required("Requerido"),
    shortDescription: Yup.string().required("Requerido"),
    tickets: Yup.string().required("Requerido"),
    maxTickets: Yup.string().required("Requerido"),
    expirationDate: Yup.string().required("Requerido"),
    status: Yup.string().required("Requerido"),
    show: Yup.string().required("Requerido"),
});

const UpdateProduct = () => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [loadedProduct, setLoadedProduct] = useState();
    const [loadedCategories, setLoadedCategories] = useState();
    const productId = useParams().productId;
    const history = useHistory();

    const [editorStateSummary, setEditorStateSummary] = useState();
    const [editorStateMoreInfo, setEditorStateMoreInfo] = useState();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/categories`
                );
                setLoadedCategories(responseData.categories);
            } catch (err) {}
        };
        fetchCategories();
    }, [sendRequest]);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_API_URL}/api/products/${productId}/${auth.userId}`,
                    "GET",
                    null,
                    { Authorization: "Bearer " + auth.token }
                );
                setLoadedProduct(responseData.product);
            } catch (err) {
                console.log(err);
            }
        };
        fetchProduct();
    }, [sendRequest, productId, auth.token, auth.userId]);

    useEffect(() => {
        if (loadedProduct) {
            setEditorStateSummary(
                EditorState.createWithContent(
                    convertFromRaw(JSON.parse(loadedProduct.summary))
                )
            );
            setEditorStateMoreInfo(
                EditorState.createWithContent(
                    convertFromRaw(JSON.parse(loadedProduct.moreInfo))
                )
            );
        }
    }, [loadedProduct]);

    if (isLoading) {
        return (
            <div className="center">
                <LoadingSpinner />
            </div>
        );
    }

    if (!loadedProduct && !error) {
        return (
            <div className="center">
                <Card>
                    <h2>Could not find product!</h2>
                </Card>
            </div>
        );
    }

    return (
        <React.Fragment>
            <GenericModal
                message={error}
                show={!!error}
                error={true}
                title={"Ocurrió un error"}
                onClear={clearError}
            />
            {loadedProduct && (
                <BannerCategory
                    title={`Editar Producto: ${loadedProduct.title}`}
                />
            )}
            {loadedProduct && (
                <Formik
                    initialValues={{
                        title: loadedProduct.title,
                        category: loadedProduct.category._id,
                        price: loadedProduct.price,
                        imageUrl: loadedProduct.imageUrl,
                        shortDescription: loadedProduct.shortDescription,
                        tickets: loadedProduct.tickets,
                        maxTickets: loadedProduct.maxTickets,
                        expirationDate: new Date(loadedProduct.expirationDate),
                        status: loadedProduct.status,
                        winner: loadedProduct.winner,
                        lotteryDate: new Date(loadedProduct.lotteryDate),
                        show: loadedProduct.show,
                    }}
                    validationSchema={HelpSchema}
                    onSubmit={async (values) => {
                        try {
                            const formData = new FormData();
                            formData.append("title", values.title);
                            formData.append("category", values.category);
                            const summary = JSON.stringify(
                                convertToRaw(
                                    editorStateSummary.getCurrentContent()
                                )
                            );
                            const moreInfo = JSON.stringify(
                                convertToRaw(
                                    editorStateMoreInfo.getCurrentContent()
                                )
                            );
                            formData.append(
                                "shortDescription",
                                values.shortDescription
                            );
                            formData.append("summary", summary);
                            formData.append("moreInfo", moreInfo);
                            if (values.imageUrl.length > 0) {
                                for (
                                    let i = 0;
                                    i < values.imageUrl.length;
                                    i++
                                ) {
                                    formData.append(
                                        "imageUrl",
                                        values.imageUrl[i]
                                    );
                                }
                            }

                            formData.append("price", values.price);
                            formData.append("tickets", values.tickets);
                            formData.append("maxTickets", values.maxTickets);
                            formData.append(
                                "expirationDate",
                                values.expirationDate
                            );
                            formData.append("status", values.status);
                            if (loadedProduct.winner) {
                                formData.append(
                                    "winner",
                                    loadedProduct.winner._id
                                );
                            } else {
                                formData.append("winner", "");
                            }

                            if (values.lotteryDate) {
                                formData.append(
                                    "lotteryDate",
                                    values.lotteryDate
                                );
                            } else {
                                formData.append("lotteryDate", "");
                            }
                            formData.append("show", values.show);

                            await sendRequest(
                                `${process.env.REACT_APP_API_URL}/api/products/${productId}`,
                                "PATCH",
                                formData,
                                { Authorization: "Bearer " + auth.token }
                            );
                            history.push("/backend/products");
                        } catch (err) {}
                    }}
                >
                    {(formProps) => (
                        <div className="product-form__background">
                            <Card className="product-form__card">
                                <h2 className="product-form__title--big">
                                    DATOS PRODUCTO
                                </h2>
                                <Form
                                    className="product-form"
                                    encType="multipart/form-data"
                                >
                                    <div className="product-form__content">
                                        <div className="product-form__input-block product-form__input-block--grid">
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="title"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Title</label>
                                                <Field
                                                    type="text"
                                                    name="title"
                                                    placeholder="Titulo"
                                                    className="product-form__form-input product-form__form-input--inline"
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="category"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Categoria</label>
                                                <Select
                                                    className="product-form__form-input product-form__form-input--inline"
                                                    name="category"
                                                >
                                                    <option value="">
                                                        Category
                                                    </option>
                                                    {loadedCategories &&
                                                        loadedCategories.map(
                                                            (items) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            items._id
                                                                        }
                                                                    >
                                                                        {
                                                                            items.title
                                                                        }
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                </Select>
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="shortDescription"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Descripción Corta</label>
                                                <Field
                                                    type="text"
                                                    name="shortDescription"
                                                    placeholder="Description"
                                                    className="product-form__form-input product-form__form-input--inline"
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="summary"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Resumen</label>
                                                <Editor
                                                    editorState={
                                                        editorStateSummary
                                                    }
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor draft-component__border"
                                                    onEditorStateChange={
                                                        setEditorStateSummary
                                                    }
                                                    stripPastedStyles={true}
                                                    toolbar={toolbarProps}
                                                />
                                            </div>

                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="moreInfo"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Más Información</label>
                                                <Editor
                                                    editorState={
                                                        editorStateMoreInfo
                                                    }
                                                    wrapperClassName="demo-wrapper"
                                                    editorClassName="demo-editor draft-component__border"
                                                    onEditorStateChange={
                                                        setEditorStateMoreInfo
                                                    }
                                                    stripPastedStyles={true}
                                                    toolbar={toolbarProps}
                                                />
                                            </div>
                                        </div>

                                        <div className="product-form__input-block product-form__input-block--grid">
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="imageUrl"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>
                                                    Imágenes de producto
                                                </label>
                                                <input
                                                    type="file"
                                                    name="imageUrl"
                                                    multiple
                                                    onChange={(event) => {
                                                        formProps.setFieldValue(
                                                            "imageUrl",
                                                            event.target.files
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="price"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Precio</label>
                                                <Field
                                                    type="number"
                                                    name="price"
                                                    placeholder="Price"
                                                    className="product-form__form-input product-form__form-input--inline"
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="tickets"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Unidades</label>
                                                <Field
                                                    type="number"
                                                    name="tickets"
                                                    placeholder="Unidades"
                                                    className="product-form__form-input product-form__form-input--inline"
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="maxTickets"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Tickets Totales</label>
                                                <Field
                                                    type="number"
                                                    name="maxTickets"
                                                    placeholder="Max Unidades"
                                                    className="product-form__form-input product-form__form-input--inline"
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="expirationDate"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>
                                                    Fecha de Vencimiento
                                                </label>
                                                <DateTimePicker
                                                    value={
                                                        formProps.values
                                                            .expirationDate
                                                    }
                                                    dateFormat="MMMM d, yyyy hh:mm:ss"
                                                    className="form-control"
                                                    timeFormat={true}
                                                    name="expirationDate"
                                                    onChange={(date) =>
                                                        formProps.setFieldValue(
                                                            "expirationDate",
                                                            date
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="status"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Status</label>
                                                <Select
                                                    className="product-form__form-input product-form__form-input--inline"
                                                    name="status"
                                                >
                                                    <option value="">
                                                        Status
                                                    </option>
                                                    <option value="active">
                                                        Active
                                                    </option>
                                                    <option value="closed">
                                                        Closed
                                                    </option>
                                                    <option value="winned">
                                                        Winned
                                                    </option>
                                                    <option value="expired">
                                                        Expired
                                                    </option>
                                                    <option value="inactive">
                                                        Inactive
                                                    </option>
                                                </Select>
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="winner"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Ganador</label>
                                                <Field
                                                    type="text"
                                                    name="winner"
                                                    placeholder="Ganador/a"
                                                    className="product-form__form-input product-form__form-input--inline"
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="lotteryDate"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Fecha de Canje</label>
                                                <DateTimePicker
                                                    value={
                                                        formProps.values
                                                            .lotteryDate
                                                    }
                                                    dateFormat="MMMM d, yyyy hh:mm:ss"
                                                    className="form-control"
                                                    timeFormat={true}
                                                    name="lotteryDate"
                                                    onChange={(date) =>
                                                        formProps.setFieldValue(
                                                            "lotteryDate",
                                                            date
                                                        )
                                                    }
                                                />
                                            </div>
                                            <div className="product-form__input-block product-form__input-block--column">
                                                <div className="product-form__error-placeholder product-form__error-placeholder--column">
                                                    <ErrorMessage
                                                        name="show"
                                                        component="div"
                                                        className="product-form__validation-error"
                                                    />
                                                </div>
                                                <label>Mostrar</label>
                                                <Select
                                                    className="product-form__form-input product-form__form-input--inline"
                                                    name="show"
                                                >
                                                    <option value="">
                                                        Mostrar
                                                    </option>
                                                    <option value="true">
                                                        True
                                                    </option>
                                                    <option value="false">
                                                        False
                                                    </option>
                                                </Select>
                                            </div>
                                        </div>

                                        <div className="help-form__footer">
                                            <div className="help-form__button-block--grid">
                                                <Button
                                                    className="main-button--squared-bigger main-button--80-width"
                                                    type="submit"
                                                    status="active"
                                                >
                                                    Editar
                                                </Button>
                                                <Button
                                                    to="/backend/products"
                                                    className="main-button--squared-bigger main-button--80-width product-form__back-button"
                                                >
                                                    Atrás
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            </Card>
                        </div>
                    )}
                </Formik>
            )}
        </React.Fragment>
    );
};
export default UpdateProduct;
