import React from "react";

import "./WinnerCard.css";
import Avatar from "../UIElements/Avatar";

import { BiCalendarStar, BiHappyBeaming } from "react-icons/bi";
import { IoTicketOutline } from "react-icons/io5";

import { DateTime } from "luxon";

// const DUMMY_WINNER_EXAMPLE = {
//     id: 1,
//     user: {
//         firstName: "Lucía",
//         lastName: "Viano",
//         email: "lucia*****@gmail.com",
//         image: "uploads/default/default-profile.jpg",
//     },
//     product: {
//         title: "Viaje a Ámsterdam",
//         imageUrl: ["uploads/images/default/seeds/product/1.jpg"],
//         lotteryDate: DateTime.now().toBSON(),
//     },
//     ticketsBought: 10,
//     chance: 20,
// },

const WinnerCard = (props) => {
    const parsedLotteryDate = DateTime.fromISO(
        props.product.lotteryDate
    ).toLocaleString();

    return (
        <div className="winner-card">
            <div className="winner-card__top">
                <div className="winner-card__avatar">
                    <Avatar
                        image={`${process.env.REACT_APP_API_URL}/${props.user.image}`}
                        alt="default avatar"
                        className="avatar--bordered-grey"
                        style={{
                            marginRight: "8px",
                            width: "36px",
                            height: "36px",
                        }}
                    ></Avatar>
                </div>
                <div className="winner-card__top-text">
                    <h4 className="winner-card__name">
                        {props.user.firstName} {props.user.lastName}
                    </h4>
                    <h5 className="winner-card__email">{props.user.email}</h5>
                </div>
            </div>
            <img
                src={`${process.env.REACT_APP_API_URL}/${props.product.imageUrl}`}
                className="winner-card__image"
                alt=""
            />
            <p className="winner-card__product">{props.product.title}</p>
            <div className="winner-card__content">
                <div className="winner-card__lotterydate">
                    <div className="winner-card__content-icons">
                        <BiCalendarStar />
                    </div>
                    <div>
                        Canje realizado el
                        <span className="winner-card__content--bold">
                            {" "}
                            {parsedLotteryDate}
                        </span>
                    </div>
                </div>
                <div className="winner-card__tickets">
                    <div className="winner-card__content-icons">
                        <IoTicketOutline />
                    </div>
                    <div>
                        Canjeo un total de{" "}
                        <span className="winner-card__content--bold">
                            {props.ticketsBought} unidades
                        </span>
                    </div>
                </div>
                <div className="winner-card__chance">
                    <div className="winner-card__content-icons winner-card__content-icons--white">
                        <BiHappyBeaming />
                    </div>
                    {props.chance}% de descuento!
                </div>
            </div>
        </div>
    );
};

export default WinnerCard;
