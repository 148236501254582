import { createContext, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import { connectHits } from "react-instantsearch-dom";
import { useHistory } from "react-router-dom";


export const SearchContext = createContext();

let searchArray = [];

const SearchContextProvider = ({ children }) => {

    const [results, setResults] = useState(null);
    const history = useHistory();

    //Algolia
    const index = process.env.REACT_APP_INDEX_NAME;
    const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
    );

    

    const handleChange = (e) => {
    history.push("/products"); 
    const { value } = e.target;

    value === "" ? setResults(null) : setResults(value);

    };


    const Hits = ({ hits }) => {

        searchArray = hits;
        //console.log(searchArray);
        
        return (
            <div>
                  
            </div>
          );
  };


  const CustomHits = connectHits(Hits);


    const contextValues = {
    searchClient,
    index,
    setResults,
    results,
    searchArray,
    CustomHits,
    handleChange,
    };

return (
    <SearchContext.Provider value={contextValues}>
        {children}
    </SearchContext.Provider>
);


}

export default SearchContextProvider;

  
